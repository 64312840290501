<template>
  <div class="placeholder">
    <font-awesome-icon :icon="['fal', 'empty-set']" />
    <div>
      {{description}}
    </div>
  </div>
</template>

<script>

export default {
  name: 'PlaceHolder',
  props: {
    description: String,
  },
};
</script>

<style scoped lang="scss">
  .placeholder {
    border: 3px dashed $gray-200;
    padding: 30px;
    text-align: center;
    border-radius: 20px;
    svg {
      font-size: 60px;
      line-height: 100px;
      color: $gray-400;
      margin-bottom: 30px;
    }
    color: $gray-500;
  }
</style>
