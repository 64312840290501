<template>
  <div class="status-page">
    <div class="logo">
      <div class="container-sm">
        <img src="https://s3.amazonaws.com/Petstablished/assets/petstablished-wagtopia-logo.png" alt="petstablished logo">
      </div>
    </div>
    <div class="container-sm mb-5">
      <div v-if="activeIncident && activeIncident.fields && activeIncident.fields.active">
        <ActiveReport />
      </div>
      <div v-else>
        <SystemOperational />
      </div>
      <StatusList />
      <ServiceList :services="services" :activeIncident="activeIncident" />
      <ReportsPagination />
      <div v-if="inProgress" class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else-if="history.length">
        <div v-for="(data, index) in Object.entries(monthlyData)" :key="index">
          <MonthReport :label="data[0]" :history="data[1]" :reportIndex="index" />
        </div>
      </div>
      <PlaceHolder description="No reports are available for this time period." v-else />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import ServiceList from '@/components/ServiceList.vue';
import StatusList from '@/components/StatusList.vue';
import MonthReport from '@/components/MonthReport.vue';
import ActiveReport from '@/components/ActiveReport.vue';
import SystemOperational from '@/components/SystemOperational.vue';
import ReportsPagination from '@/components/ReportsPagination.vue';
import PlaceHolder from '@/components/PlaceHolder.vue';

export default {
  name: 'HomeView',
  components: {
    ServiceList,
    StatusList,
    MonthReport,
    ActiveReport,
    SystemOperational,
    ReportsPagination,
    PlaceHolder,
  },
  methods: {
    ...mapActions(['initializeDates', 'goBack', 'goNext']),
  },
  computed: {
    ...mapState(['services', 'history', 'activeIncident', 'startDate', 'endDate', 'inProgress']),
    monthlyData() {
      const data = {};
      this.history.forEach((event) => {
        const key = moment(event.sys.createdAt).format('MMMM YYYY');
        if (data[key] && data[key].length) {
          data[key].push(event);
        } else {
          data[key] = [event];
        }
      });
      return data;
    },
  },
  mounted() {
    // TODO: show loader
    this.$store.dispatch('initializeDates');
    this.$store.dispatch('getStatus');
  },
};
</script>

<style scoped lang="scss">
  .status-page {
    .logo {
      background: #E4EAF9;
      min-height: 250px;
      img {
        height: 100px;
      }
    }
    > div:last-child {
      margin-top: -135px;
    }
  }
</style>
