<template>
  <div v-if="downServices.length" class="report">
    <div class="header">Active Incident</div>
    <ActiveIncident
      :event="downServices[0] ? downServices[0] : {}"
      :status="activeIncident.fields[downServices[0].service]"
      :date="activeIncident.sys.createdAt"
    />
    <div class="collapse" id="collapsedServices">
      <div v-for="(service, index) in downServices.slice(1)" :key="index">
        <ActiveIncident
          :event="service"
          :status="activeIncident.fields[service.service]"
          :date="activeIncident.sys.createdAt"
        />
      </div>
    </div>
  </div>
  <div v-if="downServices.length > 1" class="d-flex justify-content-center">
    <button class="btn btn-lg collapse-btn"
            data-bs-toggle="collapse" data-bs-target="#collapsedServices"
            @click="expanded = !expanded">
      <font-awesome-icon :icon="['fas', expanded ? 'chevron-up' : 'chevron-down']" />
      <span>{{buttonLabel}}</span>
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import serviceNames from '@/constants/Services';
import ActiveIncident from '@/components/ActiveIncident.vue';

export default defineComponent({
  name: 'ActiveReport',
  data() {
    return {
      serviceNames,
      downServices: [],
      expanded: false,
    };
  },
  computed: {
    ...mapState(['activeIncident']),
    buttonLabel() {
      if (this.expanded) return 'Show less';
      return `Show all ${this.downServices.length} reports`;
    },
  },
  components: {
    ActiveIncident,
  },
  mounted() {
    const { active, description, ...services } = this.activeIncident.fields;
    Object.keys(this.serviceNames).forEach((service) => {
      if (services[service] !== 'Online') {
        this.downServices.push({ service, description: services[`${service}Description`] });
      }
    });
  },
});
</script>

<style scoped lang="scss">
  .report {
    box-shadow: 0 3px 10px rgba(23, 33, 73, 0.05);
    border-radius: 20px;
    background-color: #fff;
    z-index: 2;
    .header {
      border-radius: 20px 20px 0 0;
      background: #E15F6C;
      font-weight: $semi-bold;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.01em;
      color: #FFFFFF;
      padding: 25px 10px;
      @include media-breakpoint-up(sm) {
        padding: 25px 40px;
      }
    }
    .event:last-child {
      border-radius: 0 0 20px 20px;
    }
  }
</style>
