import { createStore } from 'vuex';
import moment from 'moment';
import * as API from './api';

const addThreeMonths = (date) => moment(date).add(3, 'months').format('YYYY-MM-DD');
const subtractThreeMonths = (date) => moment(date).subtract(3, 'months').format('YYYY-MM-DD');

export default createStore({
  state: {
    services: {},
    description: '',
    history: [],
    activeIncident: {},
    startDate: '',
    endDate: '',
    inProgress: false,
  },
  getters: {
  },
  mutations: {
    setStatus: (state, services) => {
      const servicesStatus = { ...services };
      state.description = servicesStatus.description;
      delete servicesStatus.description;
      delete servicesStatus.active;
      state.services = servicesStatus;
    },
    setActiveIncident: (state, item) => {
      state.activeIncident = item;
    },
    setHistory: (state, items) => {
      state.inProgress = false;
      state.history = items;
    },
    setDates: (state, dates) => {
      state.startDate = dates.start;
      state.endDate = dates.end;
    },
    goBack: (state) => {
      state.inProgress = true;
      state.startDate = subtractThreeMonths(state.startDate);
      state.endDate = subtractThreeMonths(state.endDate);
    },
    goNext: (state) => {
      state.inProgress = true;
      state.startDate = addThreeMonths(state.startDate);
      state.endDate = addThreeMonths(state.endDate);
    },
  },
  actions: {
    getStatus: async ({ commit, state }) => {
      const response = await API.getStatus(state.startDate, state.endDate);
      const { items } = response;
      commit('setStatus', items[0].fields);
      commit('setActiveIncident', items.filter((item) => item.fields.active)[0]);
      if (items[0].fields.active) {
        items.shift();
      }
      commit('setHistory', items.filter((item) => !item.fields.active));
    },
    initializeDates: ({ commit }) => {
      const startDate = moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD');
      const endDate = moment().endOf('month').format('YYYY-MM-DD');
      commit('setDates', {
        start: startDate,
        end: endDate,
      });
    },
    goBack: async ({ commit, state }) => {
      commit('goBack');
      const response = await API.getStatus(state.startDate, state.endDate);
      const { items } = response;
      commit('setHistory', items.filter((item) => !item.fields.active));
    },
    goNext: async ({ commit, state }) => {
      commit('goNext');
      const response = await API.getStatus(state.startDate, state.endDate);
      const { items } = response;
      commit('setHistory', items.filter((item) => !item.fields.active));
    },
  },
  modules: {
  },
});
