import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import tz from 'moment-timezone';
import { statusColors, statusIcons } from '@/constants/Statuses';

export const formatDate = (date) => moment(date).tz('America/New_York').format('MMM DD YYYY hh:mm A z');

export const statusColor = (status) => statusColors[status];

export const statusIcon = (status) => statusIcons[status];
