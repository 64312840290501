export default Object.freeze({
  applications: 'Applications',
  emailsAndAutomatedMessages: 'Emails and automated messages',
  reports: 'Reports',
  wagtopiaMd: 'WagtopiaMD',
  wags2riches: 'Wags2Riches',
  donations: 'Donations',
  microchipSearch: 'Microchip Search',
  petSearch: 'Pet Search',
  qrCodeTagGeolocation: 'QR Code Tag Geolocation',
  petRecords: 'Updating Pet Records',
  petLostAndFound: 'Pet Lost and Found',
});
