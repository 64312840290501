<template>
  <div class="row m-0 service-list">
    <div
      class="col-xl-4 col-sm-6 mb-4 service"
      v-for="service in Object.keys(serviceNames)"
      :key="service">
        <div
          :class="
          `status-icon status-icon-${activeIncident && activeIncident.fields ?
          statusColor(services[service]) : 'success'}`
          ">
          <font-awesome-icon
            :icon="['fas', activeIncident && activeIncident.fields ?
            statusIcon(services[service]) : 'check']"
          />
        </div>
        <div>{{serviceNames[service]}}</div>
    </div>
  </div>
</template>

<script>
import serviceNames from '@/constants/Services';
import { statusColor, statusIcon } from '@/utils';

export default {
  name: 'ServiceList',
  data() {
    return {
      serviceNames,
    };
  },
  props: {
    services: Object,
    activeIncident: Object,
  },
  methods: {
    statusIcon,
    statusColor,
  },
};
</script>

<style scoped lang="scss">
  .service-list {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 3px 10px rgba(23, 33, 73, 0.05);
    padding: 30px 10px 6px;
    @include media-breakpoint-up(sm) {
      padding: 45px 50px 29px;
    }
    .service {
      display: flex;
      align-items: center;
      > div:last-child {
        font-weight: $regular;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.01em;
        color: #172149;
      }
    }
  }
  .status-icon {
    width: 26px;
    height: 26px;
    margin-right: 12px;
  }
</style>
