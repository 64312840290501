<template>
  <div class="mb-4">
    <div class="month-report">
      <div class="report-label px-2 px-sm-4">{{label}}</div>
      <ul class="list-group">
        <li class="list-group-item" v-for="(event, index) in history.slice(0, 3)" :key="index">
          <HistoryEvent :event="event"
                        :show-border="expanded || index !== history.slice(0, 3).length - 1" />
        </li>
        <li class="list-group-item collapse" :id="'report' + reportIndex"
            v-for="(event, index) in history.slice(3)" :key="index">
          <HistoryEvent :event="event" :show-border="index !== (history.slice(3).length - 1)" />
        </li>
      </ul>
    </div>
    <div v-if="historyLength > 3" class="d-flex justify-content-center">
      <button class="btn btn-lg collapse-btn"
              data-bs-toggle="collapse" :data-bs-target="'#report' + reportIndex"
              @click="expanded = !expanded">
        <font-awesome-icon :icon="['fas', expanded ? 'chevron-up' : 'chevron-down']" />
        <span>{{buttonLabel}}</span>
      </button>
    </div>
  </div>
</template>

<script>
import HistoryEvent from '@/components/HistoryEvent.vue';
import { statusIcon, statusColor } from '@/utils';

export default {
  name: 'MonthReport',
  data() {
    return {
      historyLength: 0,
      expanded: false,
    };
  },
  components: {
    HistoryEvent,
  },
  props: {
    label: String,
    history: Array,
    reportIndex: Number,
  },
  computed: {
    buttonLabel() {
      if (this.expanded) return 'Show less';
      return `Show all ${this.historyLength} reports`;
    },
  },
  methods: {
    statusIcon,
    statusColor,
  },
  mounted() {
    this.historyLength = this.history.length;
  },
};
</script>

<style scoped lang="scss">
  .month-report {
    background-color: #fff;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 16px 0;
    @include media-breakpoint-up(sm) {
      padding: 16px;
    }
  }
  .report-label {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding-bottom: 16px;
    font-weight: $semi-bold;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #172149;
  }
  .list-group-item {
    border: 0;
    padding: 20px 8px 0;
    @include media-breakpoint-up(sm) {
      padding: 20px 16px 0;
    }
  }
</style>
