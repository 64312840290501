<template>
  <div class="service mb-3">
    <div :class="`header bg-${statusColor(status)}`">{{status}}</div>
    <div class="body">
      <h1 :class="`t-${statusColor(status)}`">{{serviceNames[title]}}</h1>
      <div class="description">
        {{description ? description : 'No description available'}}
      </div>
    </div>
  </div>
</template>

<script>
import serviceNames from '@/constants/Services';
import { statusColor } from '@/utils';

export default {
  name: 'DownService',
  data() {
    return {
      serviceNames,
    };
  },
  props: {
    title: String,
    status: String,
    description: String,
  },
  methods: {
    statusColor,
  },
};
</script>

<style scoped lang="scss">
  .service {
    .header {
      border-radius: 20px 20px 0 0;
      font-weight: $semi-bold;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.01em;
      color: #FFFFFF;
      padding: 15px;
    }
    .body {
      background: #FFFFFF;
      box-shadow: 0 3px 10px rgba(23, 33, 73, 0.05);
      border-radius: 0 0  20px 20px;
      padding: 15px;
    }
    h1{
      font-weight: $semi-bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.01em;
    }
    .description {
      font-size: 12px;
      line-height: 18px;
      font-weight: $regular;
      letter-spacing: -0.01em;
      color: #172149;
    }
  }
</style>
