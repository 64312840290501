<template>
  <div class="system-operational">
    <h1>All Systems Operational</h1>
  </div>
</template>

<script>

export default {
  name: 'SystemOperational',
};
</script>

<style scoped lang="scss">
  .system-operational {
    background: #4B9E77;
    box-shadow: 0 3px 10px rgba(23, 33, 73, 0.05);
    border-radius: 20px;
    padding: 25px 10px;
    @include media-breakpoint-up(sm) {
      padding: 25px 40px;
    }
    h1 {
      font-weight: $semi-bold;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.01em;
      color: #FFFFFF;
      margin-bottom: 0;
    }
  }
</style>
