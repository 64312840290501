<template>
  <div class="legend my-3">
    <div>
      <div :class="`status-icon status-icon-${statusColor('Online')}`">
        <font-awesome-icon :icon="['fas', statusIcon('Online')]" />
      </div>
      <div>Online</div>
    </div>
    <div>
      <div :class="`status-icon status-icon-${statusColor('Degraded Performance')}`">
        <font-awesome-icon :icon="['fas', statusIcon('Degraded Performance')]" />
      </div>
      <div>Degraded Performance</div>
    </div>
    <div>
      <div :class="`status-icon status-icon-${statusColor('Offline')}`">
        <font-awesome-icon :icon="['fas', statusIcon('Offline')]" />
      </div>
      <div>Offline</div>
    </div>
  </div>
</template>

<script>
import { statusColor, statusIcon } from '@/utils';

export default {
  name: 'ServiceList',
  methods: {
    statusIcon,
    statusColor,
  },
};
</script>

<style scoped lang="scss">
  .legend {
    padding: 30px 30px 14px;
    background: rgba(#FFFFFF, 0.4);
    border-radius: 20px;
    > div {
      display: flex;
      align-items: center;
      margin-right: 3rem;
      margin-bottom: 1rem;
      > div:last-child {
        margin-right: 0;
        font-weight: $semi-bold;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.01em;
        color: #172149;
      }
      @include media-breakpoint-up(sm) {
        display: inline-flex;
        margin-right: 5rem;
      }
    }
    .status-icon {
      width: 16px;
      height: 16px;
      margin-right: 18px;
      svg {
        width: 10px;
      }
    }
    > div:nth-child(2) svg {
      width: 8px;
    }
  }
</style>
