<template>
  <div class="event">
    <div class="arrow-right">
      <font-awesome-icon :icon="['fas', 'arrow-right']" />
    </div>
    <div>
      <div class="service-name">
        <span class="me-3">{{serviceNames[event.service]}}</span>
        <span
          :class="`rounded-pill badge bg-${statusColors[status]}`">
          {{status}}
        </span>
      </div>
      <div class="date">{{formatDate(date)}}</div>
      <div class="description" v-html="marked(event.description ?
       event.description : 'No description available.')"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { marked } from 'marked';
import serviceNames from '@/constants/Services';
import { statusColors } from '@/constants/Statuses';
import { formatDate } from '@/utils';

export default defineComponent({
  name: 'ActiveIncident',
  data() {
    return {
      serviceNames,
      statusColors,
    };
  },
  props: {
    event: Object,
    status: String,
    date: String,
  },
  methods: {
    marked(markdown) {
      return marked(markdown).replaceAll('<p>', '<p class="mb-0">');
    },
    statusColor(status) {
      return this.statusColors[status];
    },
    formatDate,
  },
});
</script>

<style scoped lang="scss">
  .event {
    display: flex;
    align-items: flex-start;
    background-color: white;
    letter-spacing: -0.01em;
    border-radius: 20px;
    padding: 25px 10px;
    @include media-breakpoint-up(sm) {
      padding: 25px 40px;
    }
    .arrow-right {
      margin-right: 10px;
      margin-top: 1px;
      @include media-breakpoint-up(sm) {
        margin-right: 40px;
      }
      svg {
        color: $gray-500;
        border-color: $gray-500;
      }
    }
    .service-name {
      font-size: 16px;
      line-height: 19px;
      font-weight: $bold;
      margin-bottom: 10px;
      span {
        vertical-align: middle;
        display: inline-block;
      }
      .rounded-pill {
        font-size: 10px;
        line-height: 12px;
        letter-spacing: -0.01em;
        padding: 4px 10px 6px;
        font-weight: $regular;
      }
    }
    .date {
      font-size: 14px;
      line-height: 16px;
      color: rgba(23, 33, 73, 0.6);
      font-weight: $regular;
      margin-bottom: 10px;
    }
    .description {
      font-size: 14px;
      line-height: 22px;
      color: #172149;
      font-weight: $regular;
    }
  }
</style>
