<template>
  <div class="pagination text-center text-sm-start flex-column flex-sm-row my-4">
    <h3 class="me-sm-5">Reports History</h3>
    <div class="d-flex align-items-center">
      <button class="navigate-icon" @click="goBack">
        <font-awesome-icon :icon="['fas', 'chevron-left']" />
      </button>
      <span class="date">{{displayStartDate(startDate)}} to {{displayStartDate(endDate)}}</span>
      <button class="navigate-icon" @click="goNext">
        <font-awesome-icon :icon="['fas', 'chevron-right']" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'ReportsPagination',
  methods: {
    ...mapActions(['goBack', 'goNext']),
    displayStartDate(date) {
      return moment(date).format('MMMM YYYY');
    },
  },
  computed: {
    ...mapState(['startDate', 'endDate']),
  },
};
</script>

<style scoped lang="scss">
  .pagination {
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #172149;
    opacity: 0.6;
    h3 {
      font-weight: $bold;
      font-size: 22px;
      line-height: 26px;
    }
    .date {
      font-weight: $regular;
      font-size: 14px;
      line-height: 16px;
    }
    .navigate-icon {
      background-color: #fff;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: none;
      svg {
        opacity: 1;
        color: #172149;
        height: 14px;
      }
    }
    .navigate-icon:first-child {
      margin-right: 15px;
    }
    .navigate-icon:last-child {
      margin-left: 15px;
    }
  }
</style>
