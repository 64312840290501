import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

// Solid icons:
import {
  faCheck,
  faTimes,
  faAngleDoubleDown,
  faArrowRight,
  faArrowDown,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';

// Light icons:
import {
  faEmptySet,
  faArrowRight as fasFaArrowRight,
  faArrowDown as fasFaArrowDown,
} from '@fortawesome/pro-light-svg-icons';

// Add imported icons to library:
library.add(
  faCheck,
  faTimes,
  faAngleDoubleDown,
  faArrowRight,
  faArrowDown,
  faEmptySet,
  fasFaArrowRight,
  faChevronUp,
  faChevronDown,
  fasFaArrowDown,
  faChevronLeft,
  faChevronRight,
);

export { FontAwesomeIcon };
