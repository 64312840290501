<template>
  <div class="event">
    <div>
      <button class="btn border-0 arrow-right" data-bs-toggle="collapse"
              :data-bs-target="'#e' + event.sys.id"
              @click="expanded = !expanded">
        <font-awesome-icon :icon="['fas', expanded ? 'arrow-down' : 'arrow-right' ]"
                           :class="{ 'w-10' : expanded }" />
      </button>
      <div :class="{'border-left' : expanded }"></div>
    </div>
    <div class="event-details" :class="{'border-bottom' : showBorder }">
      <div class="d-flex align-items-center flex-wrap mb-3">
        <div class="title mt-1"> Report details </div>
        <span
          :class="`me-1 mt-1 rounded-pill badge bg-${statusColor(event.fields[service.title])}`"
          v-for="service in downServices"
          :key="service.title">
            {{serviceNames[service.title]}}
        </span>
      </div>
      <div class="description" v-html="marked(event.fields.description ?
      event.fields.description : 'No description available.')"></div>
      <div class="date mb-3">{{formatDate(event.sys.createdAt)}}</div>
      <div class="collapse" :id="'e' + event.sys.id">
        <div v-for="(service, index) in downServices" :key="index">
          <DownService :title="service.title" :status="service.status"
                       :description="service.description" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { marked } from 'marked';
import serviceNames from '@/constants/Services';
import DownService from '@/components/DownService.vue';
import { formatDate, statusColor } from '@/utils';

export default {
  name: 'HistoryEvent',
  data() {
    return {
      serviceNames,
      downServices: [],
      expanded: false,
    };
  },
  props: {
    event: Object,
    showBorder: Boolean,
  },
  methods: {
    marked(markdown) {
      return marked(markdown).replaceAll('<p>', '<p class="mb-3">');
    },
    statusColor,
    formatDate,
  },
  components: {
    DownService,
  },
  mounted() {
    Object.keys(this.serviceNames).forEach((service) => {
      if (this.event.fields[service] !== 'Online') {
        this.downServices.push({ title: service, status: this.event.fields[service], description: this.event.fields[`${service}Description`] });
      }
    });
  },
};
</script>

<style scoped lang="scss">
  .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  .border-left {
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    position: absolute;
    left: 37px;
    top: 60px;
    height: max(calc(100% - 105px), 55px);
    @include media-breakpoint-only(xs) {
      left: 30px;
    }
  }
  .w-10 {
    width: 10px;
  }
  .event {
    display: flex;
    align-items: flex-start;
    .arrow-right {
      cursor: pointer;
      margin-top: 8px;
      margin-right: 0;
      @include media-breakpoint-up(sm) {
        margin-right: 18px;
      }
    }
    .title {
      font-weight: $semi-bold;
      font-size: 18px;
      letter-spacing: -0.01em;
      color: rgba(23, 33, 73, 0.8);
      margin-right: 8px;
    }
    .description, .date {
      font-weight: $regular;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: #172149;
    }
    .date {
      color: rgba(23, 33, 73, 0.6);
    }
    > div:last-child {
      width: 100%;
      .badge {
        padding: 4px 10px 6px;
        font-weight: $regular;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }
  }
</style>
