import { createApp } from 'vue';
import { FontAwesomeIcon } from './icons';
import 'bootstrap';
import './assets/css/style.scss';
import App from './App.vue';
import router from './router';
import store from './store';

document.title = 'Petstablished Status';
const app = createApp(App).use(store);
app.use(router);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');
