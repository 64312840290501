const statusIcons = Object.freeze({
  Online: 'check',
  Offline: 'times',
  'Degraded Performance': 'angle-double-down',
});

const statusColors = Object.freeze({
  Online: 'success',
  Offline: 'danger',
  'Degraded Performance': 'warning',
});

module.exports = {
  statusIcons,
  statusColors,
};
